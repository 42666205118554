import { useFlash } from '@/lib/hooks/useFlash';
import { Flash } from '@/ui/Flash';

export const FlashMessage = () => {
  const { flash, hideFlash } = useFlash();

  return (
    <Flash
      message={flash.message || ''}
      type={flash.type}
      isOpen={flash.isOpen}
      onClose={hideFlash}
    />
  );
};
