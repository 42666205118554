import { ReactNode, useCallback, useState } from 'react';

import { FlashContext } from '@/ui/FlashMessage/context';
import { FlashMessageType, FlashState } from '@/ui/FlashMessage/types';

type Props = {
  children: ReactNode;
};

export function FlashProvider({ children }: Props) {
  const [flash, setFlash] = useState<FlashState>({ isOpen: false });

  const showFlash = useCallback((message: string, type: FlashMessageType) => {
    setFlash({ message, type, isOpen: true });
  }, []);

  const hideFlash = useCallback(() => {
    setFlash({ isOpen: false });
  }, []);

  return (
    <FlashContext.Provider value={{ flash, showFlash, hideFlash }}>
      {children}
    </FlashContext.Provider>
  );
}
