// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJsonSafely = <T = any>(json?: string | null): T | null => {
  try {
    return json && json.length > 0 && JSON.parse(json);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return null;
    }
    throw e;
  }
};
