import { uniq } from '@/lib/utils/array';
import { FormularyItem } from '@/types/core/FormularyItem';
import { MedicationOrderTemplate } from '@/types/core/MedicationOrderTemplate';

export const getFormularyItemNdcs = (
  item: FormularyItem,
  templates: MedicationOrderTemplate[],
) => {
  /**
   * For Zepbound, we should only match auto-injector templates,
   * as solution-vials are not covered by plan sponsors in the formulary.
   */
  const matcher =
    item.medication.slug == 'zepbound'
      ? /zepbound.*auto-injector/i
      : new RegExp(item.medication.name, 'i');

  const matchedTemplates = templates.filter(({ name }) => matcher.test(name));
  return uniq(matchedTemplates.map(({ ndcs }) => ndcs).flat());
};

const cashPayNotesBySlug = {
  contrave: 'Ridgeway and Pharmacy Arts Only',
  'zepbound-solution': 'LillyDirect Pharmacy Only',
  wegovy: 'NovoCare Pharmacy Only',
} as const;

export const cashPayItemsFromTemplates = ({
  formulary,
  templates,
  cashPayNdcs,
}: {
  formulary: FormularyItem[];
  templates: MedicationOrderTemplate[];
  cashPayNdcs?: string[];
}) => {
  const items = formulary.filter((item) => {
    const ndcs = getFormularyItemNdcs(item, templates);
    return ndcs.some((code) => cashPayNdcs?.includes(code));
  });

  items.push(ZEPBOUND_VIALS);

  return items.map((item) => {
    const cashPayNotes =
      cashPayNotesBySlug[
        item.medication.slug as keyof typeof cashPayNotesBySlug
      ];
    return { ...item, isCashPay: true, cashPayNotes };
  });
};

export const isApproved = ({
  template,
  formulary,
}: {
  template: MedicationOrderTemplate;
  formulary: FormularyItem[];
}) => {
  const unapprovedItems = formulary.filter((item) => !item.isApproved);
  const normalizedName = template.name.toLowerCase();
  return !unapprovedItems.some(({ medication }) =>
    normalizedName.includes(medication.name.toLowerCase()),
  );
};

export const isCashPay = ({
  template,
  cashPayNdcs,
  formulary,
}: {
  template: MedicationOrderTemplate;
  cashPayNdcs: string[];
  formulary: FormularyItem[];
}) => {
  return (
    (formulary.length === 0 || !isApproved({ template, formulary })) &&
    cashPayNdcs.length > 0 &&
    template.ndcs.some((ndc) => cashPayNdcs.includes(ndc))
  );
};

const ZEPBOUND_VIALS: FormularyItem = {
  medication: {
    slug: 'zepbound-solution',
    name: 'Zepbound Solution Vials',
  },
  isApproved: false,
  isLegacy: false,
  approvedByToc: false,
  isOnFormulary: false,
  requiresPriorAuthorization: false,
  conditions: [],
  minScore: null,
  isCashPay: true,
};
