// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"dadf18279eb0fbf1c03eced616b93fa0e02c1ff7"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";globalThis["_sentryAssetPrefix"] = undefined;globalThis["_sentryExperimentalThirdPartyOriginStackFrames"] = undefined;import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://fb9f2954a0be4e29b76aaa34f53b25a4@o4504544962805760.ingest.us.sentry.io/4504544963657728',

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],

  enabled: DEPLOY_ENV ? DEPLOY_ENV !== 'development' : false,
  environment: DEPLOY_ENV || 'development',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: DEPLOY_ENV === 'production' ? 0.1 : 1,

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // result in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
});
