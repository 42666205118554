import { formatLocalDate } from '@/lib/utils';

const OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: undefined,
  year: undefined,
  month: 'numeric',
  day: 'numeric',
};

export const isAnniversary = (date?: Date) =>
  date
    ? formatLocalDate(date, OPTIONS) === formatLocalDate(new Date(), OPTIONS)
    : false;
