import { createContext } from 'react';

import { FlashMessageType, FlashState } from '@/ui/FlashMessage/types';

type FlashContextType = {
  flash: FlashState;
  showFlash: (message: string, type: FlashMessageType) => void;
  hideFlash: () => void;
};

export const FlashContext = createContext<FlashContextType | undefined>(
  undefined,
);
