import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { useEffect } from 'react';

import { FlashMessageType } from '@/ui/FlashMessage/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  type?: FlashMessageType;
};

export const Flash = ({
  isOpen,
  onClose,
  message,
  type = 'success',
}: Props) => {
  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isOpen, onClose]);

  const styles = {
    success: {
      container: 'bg-form-green-100 border-b-form-green-400',
      iconColor: 'text-form-green-400',
      Icon: CheckCircleIcon,
    },
    alert: {
      container: 'bg-red-100 border-b-red-400',
      iconColor: 'text-red-400',
      Icon: ExclamationCircleIcon,
    },
    warning: {
      container: 'bg-yellow-100 border-b-yellow-400',
      iconColor: 'text-yellow-400',
      Icon: InformationCircleIcon, // Use an icon suitable for warnings
    },
  };

  const { container, iconColor, Icon } = styles[type];

  return (
    <Transition
      show={isOpen}
      enter="transition duration-150"
      enterFrom="opacity-0 -translate-y-4"
      enterTo="opacity-100 translate-y-0"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed inset-x-0 top-0 z-30"
    >
      <div
        className={`flex items-center justify-center gap-2 border-b py-4 ${container}`}
      >
        <Icon className={`size-6 ${iconColor}`} />
        <div className="font-semibold">{message}</div>
      </div>
    </Transition>
  );
};
